<template>
  <div>
    <div :class="$style.home">
      <div :class="$style.add" @click="onAdd()">
        <div :class="$style.icon">
          <i class="ri-add-line" />
        </div>
        <div>授权新的自媒体</div>
      </div>
      <div v-for="item in data" :key="item.id" :class="$style.box" @click="onDetail(item)">
        <div :class="$style.icon">
          <img :class="$style.avator" :src="item.avatar" />
          <img :class="$style.type" :src="item.platform_icon" />
        </div>
        <div :class="$style.text">
          <p :class="$style.title">
            {{ item.nickname }}
            <BjTag :class="$style.state" :type="item.status === 1 ? 'primary' : 'danger'">
              {{ item.status ? '正常' : '异常' }}
            </BjTag>
          </p>
          <p :class="$style.time">{{ item.authorizer_time }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { spreadManageService } from '@/service'

const service = new spreadManageService()

export default {
  name: 'Home',
  data() {
    return {
      data: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const { data } = await service.getList()
      this.data = data
    },
    onAdd() {
      this.$router.push({
        name: 'spreadManageAdd',
      })
    },
    onDetail(item) {
      this.$router.push({
        name: 'spreadManageDetail',
        query: {
          id: item.id,
        },
      })
    },
  },
}
</script>

<style lang="less" module>
.home {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .add {
    width: 280px;
    height: 90px;
    display: flex;
    border: 1px solid #eee;
    margin-bottom: 20px;
    margin-right: 20px;
    align-items: center;
    background: #fff;
    font-size: 16px;
    cursor: pointer;

    .icon {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background: #fafafa;
      text-align: center;
      line-height: 50px;
      margin: 0 14px 0 20px;
      font-size: 24px;
    }
  }

  .box {
    width: 280px;
    height: 90px;
    display: flex;
    border: 1px solid #eee;
    margin-bottom: 20px;
    margin-right: 20px;
    background: #fff;
    align-items: center;
    cursor: pointer;

    .icon {
      width: 50px;
      height: 50px;
      position: relative;
      margin: 0 14px 0 20px;

      .avator {
        width: 50px;
        height: 50px;
        border-radius: 100%;
      }

      .type {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 20px;
        height: 20px;
        border-radius: 100%;
      }
    }

    .text {
      flex: 1;
      padding-right: 20px;

      p {
        margin-bottom: 0;
      }

      .title {
        margin-bottom: 4px;
        font-size: 16px;
      }

      .time {
        font-size: 12px;
        color: #5c5c5c;
      }
    }

    .state {
      float: right;
    }
  }
}
</style>
